<template>
    <div style="width:100%;" class="d-flex flex-grow-1 align-center">
        <div class="d-flex align-center question-type-badge">
            <v-icon size="16" color="primary darken-1" v-text="questionIcons[question.type]"></v-icon>
            <div class="text-body-2">{{question.order+1}}</div>
        </div>
        <div class="text-caption flex-grow-1 px-4 ellipsis-text">
            {{questionText}}
        </div>
    </div>
</template>

<script>
import { questionIcons } from '../../../../../configs/questionTypes';

export default {
    data(){
        return {
          questionIcons
        }
    },
    computed: {
        questionText(){
            let text = this.question.text
            if (!text || text === 'Type your question here') {
                text = '...'
            }
            return `${this.question.is_optional ? "" : "* "}${text}`;
        }
    },
    props: ['question']
}
</script>

<style lang="scss" scoped>
.ellipsis-text{
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.question-type-badge{
    width: 52px;
    height: 24px;
    min-width: 52px;
    justify-content: space-between;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;

    background-color: var(--v-primary-lighten5);
    color: var(--v-primary-darken1);
}
</style>